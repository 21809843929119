.dropDownItem {
  width: 100%;
  height: 50px;
  padding: 0 19px;
  display: flex;
  align-items: center;
  transition: background 0.4s;
}
.dropDownItemDisabled {
}
.dropDownItemDisabled:hover {
  background: #ffffff !important;
}

.dropDownItemIconBlock {
  width: 18px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropDownItem:hover {
  background: rgba(73, 76, 82, 0.2);
}

.dropDownItem p {
  font-family: 'Formular', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  color: #222222;
}
