.navHomeTop {
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  height: 90px;
  background-color: transparent;
  z-index: 9999;
}

.navHomeTopOpened {
  max-height: 100%;
  min-height: 100vh;
  z-index: 1000;
  background: #ffff;
  display: flex;
  flex-direction: column;
  padding: 5px 14px 0 10px;
  transition: all 0ms ease 0s !important;
}

.navHomeTopOpened .navHomeTopContent {
  position: fixed;
  overflow: hidden;
  overflow-y: auto;
  flex-wrap: wrap;
  padding: 4px 14px 0 19px;
  top: 0;
  background: #fff;
  width: 101%;
}

.navHomeTopOpened .navHomeTopContent .navHomeTopMenu {
  padding: 0;
  flex: 0 0 100%;
  height: calc(100% - 50px);
  max-height: calc(100% - 50px);
  display: flex !important;
}

.navHomeTopOpened .navHomeTopContent .navHomeTopMenu ul {
  width: 100%;
  display: block;
  padding: 0 20px;
  overflow-y: auto;
  text-align: center;

  @media (max-width: 1014px) {
    display: none;
  }
}

.navHomeTopOpened .navHomeTopContent .navHomeTopMenu ul li {
  padding: 15px;
}

.navHomeTopOpened .navHomeTopContent .navHomeTopMenu ul li a {
  font-family: 'Formular', sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  text-align: center;
  color: #222222;
}

.navHomeTopOpened .navHomeTopContent .navHomeTopMenu ul li button {
  width: auto;
  margin: 0 auto;
  justify-content: center;
}

.navHomeTopSticky {
  box-shadow: none;
}

@media (min-width: 1015px) {
  .navHomeTopSticky {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  }

  .navHomeTopWhite {
    z-index: 99999;
    position: fixed;
    background: #ffffff;
  }
}

.navHomeTopContent {
  width: 100%;

  @media (max-width: 991px) {
    padding: 0 21px;
  }
}

.navHomeTopLogo {
  width: 199px;
  height: 34px;
  background: url('img/logo.svg') no-repeat center / contain;
  margin-right: 15px;
}

.navHomeTopLinks {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navHomeTopLinkWrapper {
  display: block;
  margin-left: 30px;
  position: relative;
}

//.navHomeTopLinkWrapper:last-child {
//  padding: 0 0 0 20px;
//}

.navHomeTopLinkWrapper > a:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #2e50cf;
  opacity: 0;
  transition: all 0.4s;
}

.navHomeTopLinkWrapper:hover a:before {
  opacity: 1;
}

.navHomeTopLinkWrapper:hover a {
  color: #2e50cf;
}

.navHomeTopLinkWrapper > a {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: 0.38px;
  color: #222222;
  text-decoration: none;
  height: 100%;
  display: block;
  padding: 0 5px;
}

.burgerClose {
  width: 22px;
  height: 16px;
  background: url('img/icon-close.svg') no-repeat center / cover;
  display: inline-block;
}

.burgerOpen {
  width: 22px;
  height: 16px;
  background: url('img/burger-gray.svg') no-repeat center / cover;
  display: inline-block;
}

.navHomeTopMenuMobile {
  display: none;
  width: 100%;
  flex-direction: column;
}

.navHomeTopMenuMobileMenu {
  position: relative;
  width: 100%;
  padding-bottom: 35px;
}

.navIconSocial {
  display: flex;
}

.navIconSocial a {
  margin-right: 25px;
}

.navHomeTopMenuMobileMenu:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #dde2e9;
}

.navHomeTopMenuMobileSocial {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
}

.navHomeTopMenuMobileSocial a {
  margin-bottom: 20px;
  font-family: 'Formular', sans-serif;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  color: #222222;
}

.navHomeTopMenuMobileSocial p {
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: 0.55px;
  color: #a9adbb;
}

.navHomeTopMenuMobileMenuItem h3 {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.63px;
  color: #868e9c;
  margin-bottom: 19px;
  text-transform: uppercase;
}

.menuMobileBlock {
  display: flex;
  align-items: center;
}

.menuMobileBlockText {
  display: flex;
  flex-direction: column;
  position: relative;
}

.popupProductsSnipets {
  position: absolute;
  top: 7px;
}

.borderLine {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 1155px;
  height: 1px;
  margin: 21px 0;
  background-color: #d8d8d8;
  bottom: -21px;

  @media (max-width: 1014px) {
    display: none;
  }
}

.subMenu {
  display: flex;
  align-items: center;
  margin-top: 90px;
  position: relative;
  z-index: 9998;

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media (max-width: 1014px) {
    display: none;
  }
}

.smartChain {
  display: flex;
  align-items: center;

  @media (max-width: 1014px) {
    display: none;
  }
}

.button {
  font-family: Formular, sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 8px;
  cursor: pointer;
  border: solid 1px #e6e8ef;
  background-color: var(--white);
}

.buttonActive {
  border: solid 1px #2e50cf;
  background-color: rgba(46, 80, 207, 0.15);
}

.popupProductsSnipets span {
  margin-right: 10px;
}

.popupProductsSnipets span:last-child {
  margin-right: 0;
}

.menuMobileBlockText p {
  font-family: 'Formular', sans-serif;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  color: #222222;
}

.menuMobileBlockText span {
  font-family: 'Inter', sans-serif;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.menuMobileBlock img {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}

.menuMobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.navHomeTopMenuMobileMenuItem {
  margin-top: 25px;
}

.navIconLink a {
  display: flex;
}
.navIconLink a svg g {
  outline: none;
  transition: fill 0.3s;
}

.navIconLink a:hover svg g {
  fill: #486bef;
}

.navIconLink a:focus svg g {
  fill: #486bef;
}

.navIconLink a:active svg g {
  fill: #00238f;
}

.navIconLink a:before {
  display: none !important;
}

.chainMenuMobile {
  display: none;
}

@media (max-width: 1014px) {
  .chainMenuMobile {
    display: flex;
    height: 60px;
    border-bottom: solid 1px #767b8f1a;
    border-top: solid 1px #767b8f1a;
    margin-top: 50px;
    align-items: center;
    padding: 0 20px;
  }

  .chainMenuMobile ul {
    display: flex;
  }

  .isMobile {
    display: flex;
  }

  .navHomeTopMenuMobile {
    display: flex;
  }

  .navHomeTop {
    transition: all 0ms ease 0s !important;
    height: 50px;
    position: absolute;
  }

  .navHomeTopMenu {
    justify-content: flex-start;
  }

  .navHomeTopLinkWrapper {
    margin-left: 0 !important;
  }

  .navHomeTopLinks {
    display: none;
  }

  .navHomeTopLogo {
    width: 140px;
  }

  .navHomeTopOpened .navHomeTopContent {
    padding: 8px 25px 30px 25px;
  }

  .navHomeTopOpened {
    background: rgba(127, 127, 127, 0.8);
    position: fixed !important;
  }

  .navHomeTopOpened .navHomeTopContent .navHomeTopMenu ul li a {
    font-size: 24px;
  }
}
