.smartContractInfoModal {
}

.smartContractInfoModalItem span {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.42px;
  color: #868e9c;
}

.smartContractInfoModalItem p {
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.51px;
  color: #222222;
  margin: 0;
  margin-top: 10px;
  word-wrap: break-word;
}

.actionsContractInfo {
  display: flex;
  align-items: center;
}

.actionsContractInfoItem {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-right: 25px;
  cursor: pointer;
}

.actionsContractInfoItem svg {
  margin-right: 10px;
}

.actionsContractInfoItem p {
  font-family: Formular;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.39px;
  color: #2e50cf;
  margin: 0;
}

.linkSvg {
  width: 18px;
  height: 18px;
}

.copySvg {
  width: 18px;
  height: 18px;
}

.delimiter {
  width: 100%;
  height: 1px;
  margin: 37px 0 30px;
  border: solid 1px #eaebf0;
}

@media (max-width: 1014px) {
  .actionsContractInfoItem {
    white-space: nowrap;
    margin-right: -15px;
  }
}
