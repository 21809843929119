.dropdownMobile {
  position: relative;
}

.dropdownMobileAbsolute {
  position: absolute;
  right: 20px;
  top: 44px;
}

.buttonDots {
  width: 46px;
  height: 46px;
  border-radius: 8px;
  display: flex;
  border: 1px solid #062eac26;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buttonDotsVertical {
  transition: background-color 0.4s;

  &:hover {
    background-color: #edeef0;
  }
}

.buttonDots svg {
  margin-left: 0 !important;
}

.buttonDotsItem {
}

.subMenu {
  position: absolute;
  right: -50px;
  top: 45px;
  width: max-content;
  margin: 15px 50px 85px 45px;
  border-radius: 8px;
  box-shadow: 0 30px 40px 0 rgba(73, 76, 82, 0.2);
  background-color: var(--white);
  z-index: 9999;
  overflow: hidden;
}
