.header {
  //margin-top: 120px;
}

.header > h1 {
  position: relative;
  font-family: 'Formular', sans-serif;
  font-size: 54px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #222222;
  padding-bottom: 58px;
}

.header > h1:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  width: 40px;
  height: 5px;
  background: #2e50cf;
  border-radius: 10px;
}

@media (min-width: 320px) and (max-width: 375px) {
  .header {
    margin-top: 33px;
    padding: 0 20px;
  }

  .header > h1 {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.67px;
    padding-bottom: 48px;
  }
}

@media (min-width: 375px) and (max-width: 414px) {
  .header {
    margin-top: 105px;
    padding: 0 20px;
  }

  .header > h1 {
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: 0.76px;
    color: #222222;
    padding-bottom: 41px;
  }
}

@media (min-width: 414px) and (max-width: 1014px) {
  .header {
    margin-top: 103px;
    padding: 0 20px;
  }

  .header > h1 {
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: 0.76px;
    color: #222222;
    padding-bottom: 43px;
  }
}
