.homeCard {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 30px 40px 0 rgba(73, 76, 82, 0.1);
  background-color: #ffffff;
  padding: 40px;
  position: relative;
}

.homeCard img {
  width: 38px;
  height: 38px;
  margin-bottom: 28px;
}

.animIcons {
  width: 36px;
  height: 36px;
  margin-bottom: 30px;
}

.homeCardContent {
  width: 390px;
  margin-bottom: 70px;
}

.homeCardContent h3 {
  font-family: 'Formular', sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: #222222;
  margin-bottom: 7px;
}

.homeCardContent span {
  font-family: 'Formular', sans-serif;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: 0.51px;
  color: #2e50cf;
}

.homeCardContent p {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.51px;
  color: #868e9c;
  margin-top: 17px;
}

.inDev {
  display: flex;
  align-items: center;
  margin-top: 40px;
  position: absolute;
  bottom: 40px;
  left: 40px;
}

.inDev button {
  margin-right: 20px;
}

.inDev p {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.45px;
  color: #868e9c;
  margin-left: 30px;
}

.homeCardFull {
  position: relative;
  width: 100%;
  height: max-content;
  border-radius: 20px;
  box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 40px;
  overflow: hidden;
}

.ethCardFull {
  height: 308px;
  overflow: hidden;
}

.homeCardFull img:first-child {
  width: 40px;
  margin-bottom: 30px;
}

.homeCardFull h3 {
  font-family: 'Formular', sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: #222222;
  margin-bottom: 20px;
  display: flex;
}
.homeCardFull h3 p {
  margin-left: 10px;
  color: #868e9c;
}

.bgImg {
  width: 379px;
  height: 331px;
  position: absolute;
  right: -35px;
  bottom: -68px;
}

.bgImgEth {
  right: 0;
  bottom: -10px;
  transform: scale(1.2);
}

.homeCardFull button {
  width: auto;
}

.homeCardFullDescBy {
  margin: 50px 0 0 0;
  display: flex;
}

.ethHomeCard {
  margin-top: 25px;
}

.h3MobileLockup {
  //@media (max-width: 1014px) {
  //  margin-top: 15px;
  //}
}

.homeCardLockup {
  @media (max-width: 1014px) {
    height: 100%;
    max-height: 100% !important;
  }
}

.homeCardFullDescLockup {
  @media (max-width: 1014px) {
    margin-bottom: 60px;
  }
}

.ethHomeCard button {
  margin-right: 20px;
}

.homeCardFullDescBy a {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.45px;
  color: #868e9c;
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.homeCardFullDescBy svg {
  margin-left: 7px;
}

.homeCardFullDesc {
  width: 635px;
}

.homeCardFullDesc p {
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.51px;
  color: #868e9c;
}

.snips {
  position: absolute;
  right: 40px;
  top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;

  @media (max-width: 991px) {
    width: 347px;
  }
}

.snipsItem {
  padding: 6px 10px;
  border-radius: 13px;
  font-family: 'Formular', sans-serif;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.38px;
  margin-right: 10px;
}

.snipsItem:nth-child(2) {
  margin-right: 0;
}

.snipsItem:last-child {
  margin-right: 0;
  margin-left: 10px;
}

@media (max-width: 1014px) {
  .homeCard {
    padding: 20px;
    width: 100%;
  }

  .homeCardFull {
    padding: 20px;
    position: relative;
  }

  .homeCardFullDesc {
    width: 100%;
  }

  .homeCardFullDescBy {
    margin: 22px 0 18px 0;
  }

  .homeCardFullDesc p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.42px;
  }

  .homeCardFull img:first-child {
    margin-bottom: 10px;
  }

  .homeCard:last-child {
    margin-top: 30px;
  }

  .inDev {
    margin-top: 25px;
    left: 20px;
    bottom: 20px;
  }

  .inDevDepositor {
    display: grid;
    grid-template-columns: 190px 1fr;
    gap: 10px 0;
  }

  .bgImg {
    display: none;
  }

  .homeCardContent {
    width: 100%;
  }

  .homeCardContent h3 {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
  }

  .homeCardContent span {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.42px;
  }

  .homeCardContent p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.42px;
  }

  .homeCardFullDescBy {
    flex-direction: column;
  }

  .homeCardFullDescBy button {
    width: 221px;
  }

  .homeCardFullDescBy a {
    margin: 10px 0 0 17px;
  }

  .homeCardFull h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .snips {
    top: 25px;
    right: 30px;
  }

  .ethCardFull {
    height: 295px;
    max-height: 445px !important;
    overflow: unset;
  }
  .ethHomeCard button {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .ethHomeCard {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .ethHomeCard button {
    width: 140px;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.logoHomeDepositorMixBytes {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.45px;
  color: #868e9c;
  display: flex;
  align-items: center;
  margin-left: 30px;

  @media (max-width: 991px) {
    margin-left: 2px;
  }

  svg {
    margin-left: 7px;
    margin-top: 3px;
  }
}
