.subMenuItem {
  min-height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaebf0;
  padding: 0 19px;
  cursor: pointer;
  position: relative;

  img {
    margin-right: 15px;
  }

  &:hover {
    background-color: #f8f9fc;
  }

  p {
    font-family: 'Formular', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    color: #222222 !important;
    margin-bottom: 0;
    position: relative;
    margin-left: 0 !important;
  }

  span {
    font-family: Formular, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    color: #868e9c;
  }
}

.subMenu:nth-last-child {
  border-bottom: none;
}
