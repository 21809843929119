.footer {
  position: relative;
  margin-top: 250px;
}

.footer:after {
  position: absolute;
  content: '';
  width: 571px;
  height: 2px;
  background: linear-gradient(to right, #f8f9fc 0%, #d6d8e2 99%);
  top: -85px;
  right: 15px;
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer ul > li {
  padding: 0 20px;
}

.footer ul > li:last-child {
  padding: 0 0 0 20px;
}

.footer ul > li a {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  text-align: right;
  color: #222222;
  cursor: pointer;
  transition: all 0.4s;
}

.footer ul > li:hover a {
  color: #2e50cf;
}

footer .copyright {
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: 0.55px;
  text-align: right;
  color: #a9adbb;
  margin-top: 31px;
  margin-bottom: 51px;
}

footer .copyright:before {
  position: absolute;
  content: '';
  top: -20px;
  left: 0;
  width: 100%;
  height: 1px;
  opacity: 0.1;
  background: #767b8e;
  display: none;
}

.footer img {
  display: none;
}

.navIconLink a {
  display: flex;
}
.navIconLink a svg g {
  outline: none;
  transition: fill 0.3s;
}

.navIconLink a:hover svg g {
  fill: #486bef;
}

.navIconLink a:focus svg g {
  fill: #486bef;
}

.navIconLink a:active svg g {
  fill: #00238f;
}

.navIconLinkMobile {
  display: none;
}

.navIconLinkMobile a {
  margin-right: 32px;
}

@media (max-width: 1014px) {
  .footer {
    margin-top: 70px;
    padding: 0 20px;
  }

  .navIconLinkMobile {
    display: flex;
    align-items: center;
    z-index: 9;
  }

  .footer:after {
    display: none;
  }

  .footer ul {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer img {
    display: block;
    padding-top: 80px;
    width: 117px;
    margin-bottom: 10px;
  }

  .footer ul li {
    padding: 10px 0;
  }

  .footer ul li:last-child {
    padding: 10px 0 0 0;
  }

  .copyright {
    justify-items: flex-start;
    text-align: left;
  }

  footer .copyright {
    text-align: left;
  }

  footer .copyright:before {
    display: block;
  }
}
