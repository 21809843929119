.lockDropDown {
  position: relative;
  height: 30px;
  border-radius: 8px;
  font-family: 'Formular', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.33px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
}

//
// blue
//
.blue {
  background: rgba(46, 80, 207, 0.1);
  color: #2e50cf;
  transition: all 0.4s;
}

.blue:hover,
.blue:focus {
  background: rgba(46, 80, 207, 0.2);
}

.blueActive,
.blueActive:hover,
.blueActive:focus {
  background: rgba(46, 80, 207, 1);
  color: #ffffff;
}

//
// green
//
.green {
  background: rgba(238, 239, 244, 1);
  color: #222222;
  transition: all 0.4s;
}

.green:hover,
.green:focus {
  background: #dadada;
  color: #222222;
}

.greenActive,
.greenActive:focus,
.greenActive:hover {
  background: #222222;
  color: white;
}

//
// pink
//
.pink {
  background: rgb(233, 21, 80);
  color: #ffffff;
  transition: all 0.4s;
}

.pink:hover,
.pink:focus {
  background: rgba(233, 21, 80, 0.74);
}

//
// lightGreen
//
.lightGreen {
  background: #d9f5f6;
  color: #00bac2;
  transition: all 0.4s;

  &:hover,
  &:focus {
    background: #bfeef0;
  }
}

.lightGreenActive {
  background: #00bac2 !important;
  color: #ffffff;
}

//
// lightPink
//
.lightPink {
  background: #fce6ee;
  color: #ec598c;
  transition: all 0.4s;

  &:hover,
  &:focus {
    background: #ffcdde;
  }
}

.lightPinkActive {
  background: #ec598c !important;
  color: #ffffff;
}

//
// lightBlue
//
.lightBlue {
  background: #d3e5fa;
  color: #5197eb;
  transition: all 0.4s;

  &:hover,
  &:focus {
    background: #accef6;
  }
}

.lightBlueActive {
  background: #5197eb !important;
  color: #ffffff;
}

//
// lightGold
//
.lightGold {
  background: #fcf5e3;
  color: #deb031;
  transition: all 0.4s;

  &:hover,
  &:focus {
    background: #f9eecf;
  }
}

.lightGoldActive {
  background: #deb031 !important;
  color: #ffffff;
}

//
// lightGrey
//
.lightGrey {
  background: #ecf2f8;
  color: #99a6b8;
  transition: all 0.4s;

  &:hover,
  &:focus {
    background: #dee4ec;
  }
}

.lightGreyActive {
  background: #99a6b8 !important;
  color: #ffffff;
}

//
// lightOrange
//
.lightOrange {
  background: #fbf0d6;
  color: #eeac5c;
  transition: all 0.4s;

  &:hover,
  &:focus {
    background: #f8e8be;
  }
}

.lightOrangeActive {
  background: #eeac5c !important;
  color: #ffffff;
}

//
// lightPurple
//
.lightPurple {
  background: #e2d8fd;
  color: #8a65f7;
  transition: all 0.4s;

  &:hover,
  &:focus {
    background: #d0c1fc;
  }
}

.lightPurpleActive {
  background: #8a65f7 !important;
  color: #ffffff;
}

//
// default
//
.default {
  color: #222222;
  transition: all 0.4s;
  background-color: #eeeff4;
}

.default:hover,
.default:focus {
  background: #dadada;
  color: #222222;
}

.defaultActive,
.defaultActive:focus,
.defaultActive:hover {
  background: #222222;
  color: var(--white);
}

.rotateIcon {
  transform: rotate(-180deg);
}

.lockDropDownChildrenImg {
  margin-left: 15px;
}

.lockDropDownItems {
  min-width: 160px;
  width: max-content;
  position: absolute;
  right: 0;
  top: 40px;
  border-radius: 8px;
  box-shadow: 0 30px 40px 0 rgba(73, 76, 82, 0.2);
  background-color: white;
  z-index: 99;
  overflow: hidden;
}

@media (max-width: 1014px) {
  .lockDropDown {
    font-size: 14px;
    margin: 0;
  }

  .lockDropDownItems {
    width: 180px;
  }
}
