.wrapper {
  display: block;
  margin-left: 30px;
  position: relative;
  width: 220px;
}

@media (max-width: 1014px) {
  .wrapper {
    display: none;
  }
}

.separator {
  width: 1px;
  height: 36px;
  border-radius: 8px;
  background-color: #eaebf0;
  margin-right: 18px;
}

.wallet {
  align-items: flex-start;
  cursor: pointer;
  border-radius: 8px;
  padding: 12px;
  height: 55px;
}

.wallet:hover {
  background-color: #eceff5;
}

.address {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.33px;
  color: #222222;
  margin-bottom: 3px;
}

.balance {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.91px;
  color: #868e9c;
}

.connect {
  margin-left: auto;
  //margin-right: auto;
}

.manualTransaction {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.29px;
  margin-right: 15px;
}
