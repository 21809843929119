.section {
  padding-top: 97px;
}

.paddingTop0 {
  padding-top: 0 !important;
  margin-top: -5px;
}

.section > h2 {
  font-family: 'Formular', sans-serif;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.55px;
  color: #222222;
  text-transform: capitalize;
  margin-bottom: 38px;
}

.cardsBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
}

.cardsBlockFull {
  display: grid;
  grid-template-columns: 1fr;
}

@media (max-width: 1014px) {
  .section {
    padding: 48px 20px 0 20px;
  }

  .cardsBlock,
  .cardsBlockFull {
    grid-template-columns: 1fr;
  }

  .section > h2 {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    color: #222222;
  }
}
