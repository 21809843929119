.popupProducts {
  position: absolute;
  right: -220px;
  top: 50px;
  //transform: translate(50%);
  width: 866px;
  border-radius: 10px;
  box-shadow: 0 10px 50px 0 rgba(73, 76, 82, 0.2);
  background-color: #ffffff;
  opacity: 0;
  transition: 0.4s;
  visibility: hidden;
  cursor: default;
  padding: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 170px 120px;
  grid-row-gap: 65px;
  grid-template-areas:
    'abyss ethstaking crowdfunding'
    'masternodes relative-block .';
  height: 510px;
}

.popupProductsSnipets {
  position: absolute;
}

.trigger {
  width: 120px;
  height: 47px;
  position: absolute;
  right: 50%;
  top: -6px;
  transform: translate(50%);
  cursor: default;
}

.popupServicesArrow {
  position: absolute;
  width: 30px;
  height: 12px;
  top: -10px;
  left: 67.5%;
  transform: translate(-50%);
}

.popupProductsItem h2 {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.88px;
  color: #868e9c;
  margin-bottom: 45px;
  text-transform: uppercase;
}

.popupProductsItemMenuText {
  margin-left: 30px;
  margin-top: -20px;
  position: relative;
}

.popupProductsItemMenuText p {
  font-family: 'Formular', sans-serif;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.33px;
  color: #222222;
}

.popupProductsItemMenuText span {
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-right: 10px;
}

.popupProductsItemMenuText span:last-child {
  margin-right: 0;
}

.popupProductsItemMenu {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.popupProductsItem .popupProductsItemMenu {
  margin-bottom: 25px;
}

.popupProductsItem .popupProductsItemMenu:last-child {
  margin-bottom: 0;
}

.popupProductsItem p {
  transition: all 0.4s;
}

.popupProductsItemMenu:hover p {
  color: #2e50cf;
}

/*.popupProductsItem a:hover .SiteNavItem__icon {*/
/*  --hover-dark: #2e50cf;*/
/*  --hover: #9b23ea;*/
/*  --hover-green: #00bac2;*/
/*}*/

.abyssImg {
  background: url('/img/cards/abyss.svg') no-repeat center / contain;
}

.marketplaceImg {
  background: url('/img/cards/market.svg') no-repeat center / contain;
}

.lightnodesImg {
  background: url('/img/cards/lightnodes.svg') no-repeat center / contain;
}

.darknodesImg {
  background: url('/img/cards/lightnodes-copy.svg') no-repeat center / contain;
}

.pureImg {
  background: url('/img/cards/pure.svg') no-repeat center / contain;
}

.syndicatesImg {
  background: url('/img/cards/syndicates.svg') no-repeat center / contain;
}

.daicoImg {
  background: url('/img/cards/daico.svg') no-repeat center / contain;
}

.ethImg {
  background: url('/img/cards/eth.svg') no-repeat center / contain;
}

.depositorImg {
  background: url('/img/cards/depositor.svg') no-repeat center / contain;
}

.SiteNavItem__icon {
  --hover-dark: #868e9c;
  --hover: #c2c8d2;
  --hover-green: #c2c8d2;
}

.SiteNavItem__icon circle,
.SiteNavItem__icon path,
.SiteNavItem__icon rect {
  transition: all 0.4s !important;
}

.trigger:hover .popupProducts {
  opacity: 1;
  //transform: translate(50%, 0%);
  visibility: visible !important;
}

@media (max-width: 1014px) {
  .trigger {
    display: none;
  }
}
